<template>
  <div class="account page">
    <div class="account__content">
      <div class="account__title">
        Мой профиль
      </div>
      <div class="account__form">
        <el-form
          :model="form"
          ref="personalForm"
          :show-message="false"
          :rules="rules"
        >
          <el-form-item
              label="Имя"
              prop="name"
              :class="{ focused: isActiveInput === 'name' || form.name }"
          >
            <el-input
                v-model="form.name"
                @focus="isActiveInput = 'name'"
                @blur="isActiveInput = ''"
            />
          </el-form-item>
          <el-form-item
              label="Фамилия"
              prop="surname"
              :class="{ focused: isActiveInput === 'surname' || form.surname }"
          >
            <el-input
                v-model="form.surname"
                @focus="isActiveInput = 'surname'"
                @blur="isActiveInput = ''"
            />
          </el-form-item>
          <el-form-item
              label="Email"
              prop="email"
              :class="{ focused: isActiveInput === 'email' || form.email }"
          >
            <el-input
                v-model="form.email"
                @focus="isActiveInput = 'email'"
                @blur="isActiveInput = ''"
            />
          </el-form-item>
          <el-form-item
              label="Организация"
              prop="company"
              :class="{ focused: isActiveInput === 'company' || form.company }"
          >
            <el-input
                v-model="form.company"
                @focus="isActiveInput = 'company'"
                @blur="isActiveInput = ''"
            />
          </el-form-item>
          <el-form-item
              label="Должность"
              prop="position"
              :class="{ focused: isActiveInput === 'position' || form.position }"
          >
            <el-input
                v-model="form.position"
                @focus="isActiveInput = 'position'"
                @blur="isActiveInput = ''"
            />
          </el-form-item>

          <div class="account__change_pass_btn" 
            v-if="changePass === null"
            @click="changePass = true, $refs['personalForm'].validate()" 
          >
            Изменить пароль
          </div>

          <el-form-item
              v-if="changePass"
              label="Пароль"
              prop="password"
              :class="{ focused: isActiveInput === 'password' || form.password }"
          >
            <el-input
                v-model="form.password"
                type="password"
                @focus="isActiveInput = 'password'"
                @blur="isActiveInput = ''"
            />
          </el-form-item>
          <el-form-item
            v-if="changePass"
            label="Повторите пароль"
            prop="password_confirmation"
            :class="{ focused: isActiveInput === 'password_confirmation' || form.password_confirmation }"
          >
            <el-input
              v-model="form.password_confirmation"
              type="password"
              @focus="isActiveInput = 'password_confirmation'"
              @blur="isActiveInput = ''"
            />
          </el-form-item>
          <div v-if="changePass" class="account__change_pass_btn" @click="changePass = null">Отменить</div>
          
          <div class="account__form-button">
            <ButtonElement text="Сохранить" @click.prevent="savePersonalData('personalForm')"/>
          </div>
        </el-form>
      </div>
    </div>
    <div v-if="user" class="account__footer">
      <div class="account__footer-data">
        <div class="account__footer-title">Тариф:</div>
        <div class="account__footer-info">
          <div v-if="!user.last_product" class="account__footer-info-value">
            У вас нет тарифного плана
          </div>
          <div v-else class="account__footer-info-value">
            {{ user.last_product.product.name }} <span>До {{ fixedDate(user.role_expiration_date) }}</span>
          </div>
          <router-link to="/tariffs" class="account__footer-info-link">
            {{ !user.last_product ? 'Купить тариф' : 'Сменить тариф' }}
          </router-link>
        </div>
      </div>
      <div v-if="user.last_product" class="account__footer-button">
        <ButtonElement text="Продлить" @click="updateTariff"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement'
import { DateParser } from '@/mixin/date_parser'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'Account',
  mixins: [DateParser,AlertNotify],
  components: {
    ButtonElement,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите пароль'));
      } else if(value.length < 6){
          callback(new Error('Пароль должен быть больше 6 символов'));
          this.errorNotify('Пароль должен быть больше 6 символов')
      }
      else {
        if (this.form.password_confirmation !== '') {
          this.$refs['personalForm'].validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Повтрите пароль'));
      } else if (value !== this.form.password) {
        callback(new Error('Пароли не совпадают'));
      } else {
        callback();
      }
    };
    return {
      isActiveInput: '',
      changePass: null,
      user: null,
      form: {
        name: '',
        surname: '',
        email: '',
        company: '',
        position: '',
        password: '',
        password_confirmation: ''
      },
      rules: {
        name: [
          { required: true, message: 'Введите Имя', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Введите Email', trigger: 'blur' },
          { type: 'email', message: 'Введите  корректный Email', trigger: ['blur', 'change'] }
        ],
        password: [{required: true, validator: validatePass, trigger: 'blur' }],
        password_confirmation: [{required: true, validator: validatePass2, trigger: ['blur', 'change'] }],
      },
    }
  },
  mounted() {
    this.$store.dispatch('Personal/getUser')
      .then(resp => {
        this.user = resp.data.data
        for( let key in resp.data.data){
          this.form[key] = resp.data.data[key]
        }
      })
      .catch(err => {
        console.log(err.response.data.errors)
      })
  },
  methods: {
    savePersonalData(form){
      this.$refs[form].validate(valid => {
        if(valid) {
          const data = this.form
          if(!this.changePass) {
            delete data.password
            delete data.password_confirmation
          }
          this.$store.dispatch('Personal/updateUser', data)
          .then(() => {
            this.successNotify('Данные обновлены')
            this.changePass = null
          })
          .catch(err => {
            console.log(err.response.data.errors)
          })
        } else {
          this.errorNotify('Проверьте правильность заполнениея полей')
        }
      });
    },
    updateTariff(){
      this.$store.commit('Dialog/setData', { 
        label: 'activeDialog', 
        data: 'Tariff'
      })
      this.$store.commit('Dialog/setData', { 
        label: 'titleDialog', 
        data: 'Выберите действие'
      })
      this.$store.commit('Dialog/setData', { 
        label: 'dialogVisible', 
        data: true 
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.account {
  padding-top: 6rem;
  padding-bottom: 17rem;
  position: relative;
  min-height: calc(100vh - 9rem);

  &__content {
    width: 37rem;
    margin: 0 auto;
  }

  &__title {
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.6rem;
    margin-bottom: 4rem;
  }

  &__form {
    &-button {
      display: flex;
      justify-content: center;
      margin-top: 5rem;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $lightGrey;
    padding: 3.6rem 16rem;
    display: flex;
    justify-content: space-between;

    &-button {
      width: 33rem;

      .button {
        width: 100%;
      }
    }

    &-title {
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 1rem;
    }

    &-info {
      display: flex;
      align-items: center;

      &-value {
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.8rem;
        margin-right: 13rem;

        span {
          font-size: 1.4rem;
          line-height: 1.9rem;
          font-weight: 400;
        }
      }

      &-link {
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $blue;
      }
    }
  }
  &__change_pass_btn{
    text-align: center;
    cursor: pointer;
    color: #3278e1;
    margin-top:20px;
  }
}
</style>
